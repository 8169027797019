import { GridRowParams } from '@material-ui/data-grid';
import React, { useState, useCallback, useEffect, useContext } from 'react';

import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Tabs, Tab } from 'react-bootstrap';
import { useEmissaoNFE } from './EmissaoNFEContext';
import api from '~/services/api';
import Search from '~/components/Search';
import { Container } from './styles';
import FormDefault from '~/components/FormDefault';

import { transformAsCurrency } from '~/utils/functions';
import { Nfe } from './screens/Nfe';
import { Financeiro } from './screens/Financeiro';
import { Log } from './screens/Log';
import { FieldValues } from 'react-hook-form';
import { nanoid } from 'nanoid';
import moment from 'moment';
import { LogProps, Produto } from './protocols';
import { datesScheme } from './validations/FormDataValidation';
import { Context } from '~/components/Nfe/TransmissaoNFe/Transmissao';
import { TransmitirNFe } from '~/components/TransmitirNFe';
import { loadingContext } from '~/context/loading';
import { format, parseISO } from 'date-fns';
import { createDateWithoutTimezone } from '~/utils/createDateWithoutTimezone ';

const MySwal = withReactContent(Swal);

interface AditionalValidations {
  description: string;
}

export const EmissaoNFEContent: React.FC = () => {
  const { transmitirNFe } = useContext(Context);
  const {
    currentTab,
    setCurrentTab,
    produtos,
    setProdutos,
    formNFE: { setValue, formState, handleSubmit, getValues },
    formFinanceiro,
    cuponsReferenciados,
    parcelasFinanceiro,
    setParcelasFinanceiro,
    totalFinanceiro,
    subTotais,
    isUpdate,
    setIsUpdate,
    setCodLoja,
    setLogs,
    resetFormData,
    setCuponsReferenciados,
    existeLogAcbr,
    nfManutencao,
    setNfManutencao,
    setExisteLogAcbr,
    nfCancelada,
    setNfCancelada,
  } = useEmissaoNFE();

  const { setLoading } = useContext(loadingContext);

  const [showSearch, setShowSearch] = useState<boolean>(true);
  const [searchAcbrLog, setSearchAcbrLog] = useState<boolean>(false);

  useEffect(() => {
    const { loja, busca_parceiro, cod_perfil, cod_serie, cod_especie } =
      formState.errors;
    if (
      loja === undefined ||
      busca_parceiro === undefined ||
      cod_perfil === undefined ||
      cod_serie === undefined ||
      cod_especie === undefined
    ) {
      setCurrentTab('nfe');
    }
  }, [formState.errors]);

  const onRowClick = async (param: GridRowParams) => {
    const { row } = param;

    setIsUpdate(true);
    const buscaParceiroValues = {
      nome_pessoa: row.nome_pessoa,
      flg_orgao_publico: row.flg_orgao_publico,
      des_cidade: row.des_cidade,
      des_uf: row.des_uf,
      value: row.cod_pessoa,
      label: `${row.cod_cliente} - ${row.nome_pessoa} (${row.num_cpf_cnpj})`,
    };
    setNfCancelada(row.flg_cancelada);
    setNfManutencao(row.flg_manutencao);
    setCodLoja(row.cod_loja);
    setValue('loja', row.cod_loja);
    setValue('busca_parceiro', buscaParceiroValues);
    setValue('cod_perfil', {
      value: row.cod_perfil,
      label: `${row.cod_perfil} - ${row.des_perfil}`,
      flg_calcula_st: row.flg_calcula_st,
    });
    setValue('cod_serie', {
      value: row.num_serie_nf,
      label: row.num_serie_nf,
    });

    setValue('cod_especie', {
      value: 33,
      label: 'NFE - NOTA FISCAL ELETRÔNICA',
      des_doc_fiscal: 'NOTA FISCAL ELETRÔNICA',
      des_especie: 'NFE',
      cod_doc_fiscal: '55',
      num_modelo_fiscal: '',
    });
    setValue('num_nf', row.num_nf);
    setValue(
      'dta_emissao',
      format(createDateWithoutTimezone(row.dta_emissao), 'yyyy-MM-dd'),
    );
    setValue(
      'dta_saida',
      format(createDateWithoutTimezone(row.dta_entrada_saida), 'yyyy-MM-dd'),
    );
    setValue('des_info_complementar', row.des_obs_livre);
    setValue('cod_seq_nf', row.cod_seq_nf);

    /**
     * Busca Itens Da NF
     */
    try {
      const res = await api.get(`/emissao-nf/${row.cod_seq_nf}/itens`);
      const { data } = res.data;
      const itens = data.map((item: Produto) => {
        item.val_tabela = item.val_tabela_liq;
        return item;
      });
      const orderData = itens.sort((a: any, b: any) => a.num_item - b.num_item);
      setProdutos(orderData);
    } catch (error: any) {
      if (error.data && error.data.message) {
        toast.error(error.data.message);
        return;
      }
      toast.error(String(error));
    }
    /**
     * Busca Parcelas do Financeiro
     */
    try {
      const res = await api.get(`/nf/${row.cod_seq_nf}/financeiro`);
      const { data } = res.data;

      const rowsFinanceiro = data.map((item: any) => {
        const uuid = nanoid();
        item.uuid = uuid;
        item.id = uuid;
        return item;
      });
      setParcelasFinanceiro(rowsFinanceiro);
    } catch (error: any) {
      if (error.data && error.data.message) {
        toast.error(error.data.message);
        return;
      }
      toast.error(String(error));
    }
    /**
     * Busca Cupons Referenciados
     */
    try {
      const res = await api.get(`/nf/cupom/${row.cod_seq_nf}`);
      const { data } = res.data;
      const cupons = data.map((cupom: any) => {
        return {
          cod_seq_cupom: cupom.cod_seq_cupom,
          num_cupom: cupom.num_cupom,
        };
      });
      setCuponsReferenciados(cupons);
    } catch (error: any) {
      if (error.data && error.data.message) {
        toast.error(error.data.message);
        return;
      }
      toast.error(String(error));
    }

    // let logs = [];
    // if (row.logs.length > 0 && row.logs[0] !== null) {
    //   const formatedLogs = row.logs.map((log: LogProps) => {
    //     return {
    //       id: nanoid(),
    //       ...log,
    //       dta_cadastro: log.dta_cadastro
    //         ? moment(new Date(log.dta_cadastro)).format('DD-MM-YYYY')
    //         : '',
    //     };
    //   });
    //   logs = formatedLogs;
    // }
    // if (logs.length > 0) setLogs(logs);
    setShowSearch(false);
  };

  function CreateTable(itens: AditionalValidations[]): void {
    const AlertTableHeader = '<tr><th>Descrição</th></tr>';
    const AlertTableBody = itens.map((item: AditionalValidations): string => {
      const spaco = '&nbsp;&nbsp;';
      return `<tr><td>${item.description}${spaco}</td></tr>`;
    });

    const AlertTable = `
            <div style='max-height: 200px;white-space: nowrap; border: solid 1px #dcdcdc; overflow: auto'>
              <table style='width:100%;min-width: 500px'>
                <thead>${AlertTableHeader}</thead>
                <tbody>${AlertTableBody.join(' ')}</tbody>
              </table>
            </div>
            <p style='text-align: left; padding: 10px'>
            </p>
            `;
    MySwal.fire({
      icon: 'warning',
      width: 800,
      title: 'Regularize os itens apontados para prosseguir.',
      html: String(AlertTable),
    });
  }

  const aditionalValidations = useCallback((): AditionalValidations[] => {
    const invalid: AditionalValidations[] = [];
    if (produtos.length <= 0) {
      invalid.push({
        description: 'É necessário adicionar ao menos um item na tabela.',
      });
    }
    const flg_gera_financeiro = getValues('cod_perfil')?.flg_gera_financeiro;
    if (flg_gera_financeiro && totalFinanceiro !== subTotais.totalNF) {
      invalid.push({
        description: 'Valor Total das parcelas difere do Total da NF..',
      });
    }
    return invalid;
  }, [getValues, produtos.length, subTotais.totalNF, totalFinanceiro]);

  const formatData = useCallback(
    (formData: FieldValues) => {
      /**
       * Formata Dados para insert na tab NF
       */
      const dataToInsertInNF = {
        cod_pessoa: formData.busca_parceiro.value,
        num_nf: Number(formData.num_nf),
        num_serie_nf: formData.cod_serie.value,
        cod_perfil: formData.cod_perfil.value,
        tipo_operacao: formData.cod_perfil.tipo_operacao,
        tipo_nf: formData.cod_perfil.tipo_nf,
        cod_loja: formData.loja,
        num_chave_acesso: '', // Valor fixo segundo a análise
        des_especie: 'NFE',
        cod_especie: 33,
        cod_doc_fiscal: '55',
        num_modelo_fiscal: '',
        des_natureza: formData.cod_perfil.descricao.substring(0, 35),
        val_total_nf: transformAsCurrency(subTotais.totalNF || 0),
        dta_emissao: formData.dta_emissao,
        dta_entrada: formData.dta_saida,
        flg_cancelada: false,
        num_cpf_cnpj: formData.busca_parceiro.num_cpf_cnpj,
        num_rg_ie: formData.busca_parceiro.num_ie,
        des_razao_social: formData.busca_parceiro.nome_pessoa,
        tipo_frete: 9, // Segundo a análise deve-se manter o valor fixo 9
        tipo_emitente: formData.cod_perfil.tipo_emissao === 0 ? 1 : 0,
        flg_optante_simples:
          formData.busca_parceiro.flg_optante_simples ?? false,
        flg_gera_financeiro: formData.cod_perfil.flg_gera_financeiro ?? false,
      };

      /**
       * Formata Dados para insert na tab NF CLIENTE
       */
      const dataToInsertInNFCliente = {
        cod_pessoa: formData.busca_parceiro.value,
        num_nf: Number(formData.num_nf),
        num_serie_nf: formData.cod_serie.value,
        // des_especie: 'NFE', // Campo não existe na tab NF CLIENTE
        tipo_nf: formData.cod_perfil.tipo_nf,
        cod_loja: formData.loja,
        cod_perfil: formData.cod_perfil.value,
        val_total_nf: transformAsCurrency(subTotais.totalNF || 0),
        dta_emissao: formData.dta_emissao,
        dta_entrada_saida: formData.dta_saida,
        flg_confirmar: false,
        flg_cancelada: false,
        dta_cancelamento: '',
        flg_gera_estoque: formData.cod_perfil.flg_gera_estoque,
        obs_livre: '',
        flg_orgao_publico: formData.busca_parceiro.flg_orgao_publico,
      };

      /**
       * Formata Dados para insert na tab NF CLIENTE ITEM
       */
      const itens: any = produtos.map((produto) => {
        return {
          num_item: produto.num_item,
          cod_produto: produto.cod_produto,
          cod_tributacao: produto.cod_tributacao,
          cfop: produto.cfop, // Adicionar CFOP ao select do cupom
          cod_item_embalagem: Number(produto.cod_item_embalagem),
          qtd_embalagem: Number(produto.qtd_embalagem_venda),
          qtd_entrada: produto.qtd_total_produto,
          des_unidade: produto.des_unidade_venda,
          cod_ncm: produto.cod_ncm,
          num_ncm: produto.num_ncm,
          val_tabela: transformAsCurrency(produto.val_tabela || 0),
          val_tabela_liq: transformAsCurrency(produto.val_tabela_liq || 0),
          val_tabela_final: transformAsCurrency(produto.val_tabela_final || 0),
          val_desconto: transformAsCurrency(produto.val_desconto || 0),
          val_acrescimo: transformAsCurrency(produto.val_acrescimo || 0),
          flg_bonificado: produto.flg_bonificado,
          val_ipi: transformAsCurrency(produto.val_ipi || 0),
          val_icms: transformAsCurrency(produto.val_icms || 0),
          val_bc_icms: transformAsCurrency(produto.val_bc_icms || 0),
          val_outros: transformAsCurrency(produto.val_outros || 0),
          val_isento: transformAsCurrency(produto.val_isento || 0),
          val_icms_st: transformAsCurrency(produto.val_icms_st || 0),
          val_bc_st: transformAsCurrency(produto.val_bc_st || 0),
          // val_total_nf: transformAsCurrency(subTotais.totalNF || 0), // val_total_nf, Campo contido na sql de inserção não existe na tab NF CLIENTE ITEM
          val_frete: transformAsCurrency(produto.val_frete || 0),
          // val_frete_dest: transformAsCurrency(produto.val_frete_dest || 0), // val_frete_dest, Campo contido na sql de inserção não existe na tab NF CLIENTE ITEM
          val_icms_deson: transformAsCurrency(produto.val_icms_deson || 0),
          flg_destaca_st: produto.flg_destaca_st,
          per_pis: produto.per_pis || 0,
          per_cofins: produto.per_cofins || 0,
          val_fcp_icms: transformAsCurrency(produto.val_fcp_icms || 0),
          per_fcp_icms: Number(produto.per_fcp_icms),
          flg_ignora_icms_nf_cli: produto.flg_ignora_icms_nf_cli,
          cod_id_ctb: produto.cod_id_ctb,
          des_produto: produto.des_produto,
          cod_gtin: produto.cod_gtin_principal,
          cod_sit_tributaria: produto.cod_situacao_tributaria,
          val_unitario: transformAsCurrency(produto.val_tabela || 0),
          val_total: transformAsCurrency(produto.val_total || 0),
          val_despesa_acessoria: 0, // Fixo
          tipo_tributacao: Number(produto.tipo_tributacao),
          per_aliq_icms: transformAsCurrency(produto.val_icms || 0),
          per_red_bc_icms: transformAsCurrency(produto.val_reducao || 0),
          val_venda_varejo: transformAsCurrency(produto.val_venda_pdv || 0), // Correto?
          val_base_ipi: transformAsCurrency(produto.val_base_ipi || 0),
          val_isento_ipi: transformAsCurrency(produto.val_isento_ipi || 0),
          val_outros_ipi: transformAsCurrency(produto.val_outros_ipi || 0),
          flg_nao_pis_cofins: produto.flg_nao_pis_cofins,
          tipo_nao_pis_cofins: produto.tipo_nao_pis_cofins,
          val_base_pis: transformAsCurrency(produto.val_base_pis || 0),
          val_base_cofins: transformAsCurrency(produto.val_base_cofins || 0),
          val_pis: transformAsCurrency(produto.val_pis || 0),
          val_cofins: transformAsCurrency(produto.val_cofins || 0),
        };
      });

      /**
       * Formata Dados para insert na tab NF_FORNECEDOR
       */
      const dataToInsertInFinanceiro = {
        cod_categoria: formData.cod_perfil.cod_categoria,
        cod_cc: formData.cod_perfil.cod_cc,
        des_observacao: formData.des_info_complementar,
      };

      /**
       * Formata Cupons referenciados
       */
      const cupons = cuponsReferenciados.map((cupom) =>
        String(cupom.cod_seq_cupom),
      );

      return {
        ...dataToInsertInNF,
        ...dataToInsertInNFCliente,
        ...dataToInsertInFinanceiro,
        financeiro: parcelasFinanceiro,
        cupons,
        itens,
      };
    },
    [cuponsReferenciados, parcelasFinanceiro, produtos, subTotais.totalNF],
  );

  const validaTotalParcelas = (): boolean => {
    const totalNota = subTotais.totalNF;
    const totalParcelas = parcelasFinanceiro.reduce(
      (acumulador, item) =>
        acumulador + transformAsCurrency(item.val_parcela || 0),
      0,
    );
    if (
      transformAsCurrency(totalParcelas).toFixed(2) !==
      transformAsCurrency(totalNota).toFixed(2)
    ) {
      toast.warn(
        `Total das parcelas difere do total da NF. \n
        A soma dos valores das parcelas deve ser igual ao total da nota.
        `,
      );
      return false;
    }
    return true;
  };

  const onSave = handleSubmit(async (formData) => {
    try {
      const dta_emissao = getValues('dta_emissao');
      const dta_saida = getValues('dta_saida');

      const isValid = await datesScheme
        .validate({ dta_emissao, dta_saida })
        .then(function () {
          return true;
        })
        .catch(function (erro) {
          if (erro.errors.length > 0) {
            toast.warn(erro.errors[0]);
          }
          return false;
        });

      if (!isValid) {
        setCurrentTab('nfe');
        return;
      }

      const nfValidations = aditionalValidations();
      if (nfValidations.length > 0) {
        CreateTable(nfValidations);
        return;
      }

      const parcelaValidation = validaTotalParcelas();
      if (!parcelaValidation) return;

      if (cuponsReferenciados.length <= 0) {
        setLoading(false);
        const confirma = await MySwal.fire({
          title: 'Não existe nenhum cupom amarrado.',
          text: 'Deseja gravar mesmo assim?',
          showCancelButton: true,
          confirmButtonColor: '#8850BF',
          cancelButtonColor: '#DE350B',
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
        }).then((result) => {
          if (result.isConfirmed) {
            return true;
          }
          return false;
        });
        if (confirma === false) {
          return;
        }
      }

      const formatedData = formatData(formData);
      // Chamada a API para inserir na tabela NF
      setLoading(true);
      const { data } = await api.post('/emissao-nfe', formatedData);

      if (data.success) {
        if (formData.cod_perfil.tipo_emissao === 1) {
          const { cod_seq_nf, num_nf } = data.data;

          await transmitirNFe({
            cod_loja: formData.loja,
            cod_pessoa: formData.busca_parceiro.cod_pessoa,
            num_nf,
            tipo_nf: formData.cod_perfil.tipo_nf,
            cod_seq_nf,
          });
        }

        resetFormData();
        // setShowSearch(true);
        toast.success(data.message || 'Registro inserido com sucesso');
        return;
      }
    } catch (error: any) {
      if (error.data && error.data.message) {
        if (
          error.data.message.includes(
            'Registro Bloqueado. Mês fechado pelo Fechamento Fiscal',
          )
        )
          return;

        toast.error(error.data.message);
        return;
      }
      toast.error(String(error));
    } finally {
      setLoading(false);
    }
  });

  const onNewData = () => {
    // handleClearFormEmissaoNFEItens();
    setShowSearch(false);
    setIsUpdate(false);
    // if (lojas.length === 0) {
    //   toast.error('Não há lojas para serem parametrizadas.');
    //   setShowSearch(true);
    // } else {
    //   handleClearForms();

    // }
  };

  useEffect(() => {
    (async () => {
      if (isUpdate || searchAcbrLog) {
        const loja = getValues('loja');
        const codSeqNF = getValues('cod_seq_nf');
        const { data } = await api.get(
          `/transmitir-nfe/verifica-acbr-log/${loja}/${codSeqNF}`,
        );
        setExisteLogAcbr(data);
      }
    })();
  }, [getValues, isUpdate, setExisteLogAcbr, searchAcbrLog]);

  return (
    <Container>
      {showSearch && (
        <Search codTela={47} newData={onNewData} onRowClick={onRowClick} />
      )}

      {!showSearch && (
        <FormDefault
          codTela={47}
          title="Emissão Cliente"
          codigoRegistro={[
            {
              value: getValues('cod_seq_nf'),
              des_campo: 'Código',
            },
          ]}
          onSave={onSave}
          isUpdate={isUpdate}
          onCancel={() => {
            resetFormData();
            setShowSearch(true);
          }}
          onNew={() => {
            resetFormData();
            setIsUpdate(false);
          }}
          onDelete={() => null}
          onClearFields={() => resetFormData()}
          onReturnSearch={() => {
            resetFormData();
            setIsUpdate(false);
            setShowSearch(true);
          }}
          isSave={isUpdate}
          isDelete={false}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <div>
              {nfCancelada && (
                <h3
                  style={{
                    color: 'red',
                    fontSize: '1.625rem',
                    padding: '0.3125rem',
                    lineHeight: '2.4375rem',
                    borderRadius: '0.3125rem',
                    backgroundColor: 'yellow',
                    marginLeft: '0.3rem',
                    marginTop: '0.3rem',
                  }}
                >
                  CANCELADA
                </h3>
              )}
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
              }}
            >
              {isUpdate && (
                <TransmitirNFe
                  codSeqNf={getValues('cod_seq_nf')}
                  waitForCompletion={() => {
                    setSearchAcbrLog(true);
                  }}
                />
              )}
              {nfManutencao && (
                <h3
                  style={{
                    color: 'green',
                    fontSize: '1.625rem',
                    padding: '0.3125rem',
                    lineHeight: '2.4375rem',
                    borderRadius: '0.3125rem',
                    backgroundColor: 'yellow',
                    marginLeft: '0.3rem',
                    marginTop: '0.3rem',
                  }}
                >
                  MANUT. FISCAL
                </h3>
              )}
            </div>
          </div>
          <Tabs
            id="controlled-produto"
            activeKey={currentTab}
            onSelect={(tab: any) => setCurrentTab(tab)}
          >
            <Tab eventKey="nfe" title="NF-e">
              <Nfe />
            </Tab>
            <Tab eventKey="financeiro" title="Financeiro">
              <Financeiro />
            </Tab>
            {isUpdate && (
              <Tab eventKey="log" title="Log">
                <Log />
              </Tab>
            )}
          </Tabs>
        </FormDefault>
      )}
    </Container>
  );
};
